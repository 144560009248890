import React from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import Popup from 'react-popup';
import $ from 'jquery';

import { get, post, withRouter, showLoading, hideLoading } from './funcs';
import SSFormsCustomCSS from './customcss';

const getUrl = (action, cid, fid, key) => `${process.env.REACT_APP_API_URL}/v1/webhook/client_form/${action}?company=${cid}&id=${fid}&key=${key}`;

class SSClientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { companyID: this.props.router.params.company, clientFormID: this.props.router.params.id, key: this.props.router.params.cfKey, data: null, submitted:false };
  }

  componentDidMount() {
     const url = getUrl('get', this.state.companyID, this.state.clientFormID, this.state.key);
     showLoading();
     return get(url).then(data => {hideLoading(); this.setState(prevState => ({...prevState, data: data, settings: data.settings})); return data;});
  };
  

  onSubmit = (data) => {
    const saveUrl = getUrl('update', this.state.companyID, this.state.clientFormID, this.state.key);
    showLoading();
    post(saveUrl, data).then(data => {
       hideLoading();
        if (data.status === 200) {
           Popup.create({
            title: 'Thank You',
            content: 'Your form has been submitted.',
            buttons: {
              left: [{
                  text: 'OK',
                  className: 'success',
                  action: function () { $('#root').html('You may close this window now.'); Popup.close(); }
              }]
            }
           });
           this.setState(prevState => ({...prevState, submitted: true}));
        } else
           Popup.create({
            title: 'Error',
            content: 'There was an unexpected error submitting your form.',
            buttons: {
              left: [{
                  text: 'Close',
                  action: function () { Popup.close(); }
              }]
            }
           });
     });
  };

  render() {
    return (
    <>
       {this.state.data ? this.state.submitted ? null : 
       <>
       <ReactFormGenerator
          onSubmit={this.onSubmit}
          answer_data={this.state.data.client_form_data ? JSON.parse(this.state.data.client_form_data) : []} // Answer data, only used if loading a pre-existing form with values.
          data={this.state.data.form_data ? JSON.parse(this.state.data.form_data) : []} // Question data
          read_only={this.props.readonly || this.state.data.locked}
          hide_actions={this.props.readonly || this.state.data.locked}
         />
      <SSFormsCustomCSS settings={this.state && this.state.settings ? this.state.settings : null} />         
  </>
  : null }
         </>
    );
  }
}

export default withRouter(SSClientForm);

