import React from 'react';
import { ElementStore } from 'react-form-builder2';

export default class SSFormsHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      previewVisible: false,
      companyID: this.props.companyID,
      formID: this.props.formID,
      fKey: this.props.fKey
    };

    this._onUpdate = this._onChange.bind(this);
  }

  componentDidMount() {
    ElementStore.subscribe(state => this._onUpdate(state.data));
  }

  _onChange(data) {
    this.setState(prevState => ({...prevState, data: data}));
  }

  _onSubmit(data) {
     //this is called when the Preview form is submitted - we don't need this
  }
  
  saveForm() {
     this.props.onSaveForm();
  }

  render() {
    return (
      <div className="header clearfix">
        <h4 className="float-left">Edit Form</h4>
        <div className="float-right">
        <button className="btn btn-default btn-green" style={{ marginRight: '10px' }} onClick={ () => this.props.onPreviewForm() }>Preview Form</button>
        <button id="btnSaveForm" className="btn btn-default btn-primary" onClick={ () => this.props.onSaveForm() }>Save Form</button>
        </div>

      </div>
    );
  }
}
