import React from 'react';
import ReactDOM from 'react-dom';
import Popup from 'react-popup';


import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { SSFormCombined, SSFormPreview, SSFormTemplatePreview } from './form';
import SSClientForm from './clientform';
import { hideLoading } from './funcs';

const router = createBrowserRouter([
  {
    path: "/",
    element: <p>Page not found.</p>,
    loader: ({ params }) => {
    hideLoading();
    return null;
    }
  },
  {
    path: "/forms/:company/:id/:fKey",
    element: <>
    <SSFormCombined />
    <div id="version">v1.0.317bd29</div>
    </>
  },
  {
    path: "/forms/preview/template/:id",
    element: <SSFormTemplatePreview />
  },
  {
    path: "/forms/preview/:company/:id/:fKey",
    element: <SSFormPreview />
  },
  {
    path: "/:company/:id/:cfKey",
    element: <SSClientForm />
  },
  {
    path: "/:company/:id/:cfKey/answers",
    element: <SSClientForm readonly />
  }
]);

ReactDOM.render(
  <RouterProvider router={router} />,
  document.getElementById('root'),
);
ReactDOM.render(
    <Popup closeBtn={false} />,
    document.getElementById('popupContainer')
);