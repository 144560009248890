import React from 'react';

export default class SSFormsCustomCSS extends React.Component {
  
  getSetting = (name) => {
     if (!this.props.settings || this.props.settings.length<1)
        return '';
     for(var i=0;i<this.props.settings.length;i++)
        if (this.props.settings[i].name===name)
           return this.props.settings[i].value;
     return '';
  }

  render() {
     var txt = `<style type="text/css">
        body {
           background-color: FBC;
        }
         h3 {
            color: OBST;
         }
         input[type=submit].btn-big {
           background-color: OBSB;
         }
         input[type=submit].btn-big:hover {
            background-color: OBSB;
         }
      </style>`
      txt = txt.replace('FBC', this.getSetting('form_background_colour'));
      txt = txt.replace('OBST', this.getSetting('online_booking_style_title'));
      txt = txt.replace(/OBSB/g, this.getSetting('online_booking_style_button'));
    return (
    <>
      <div dangerouslySetInnerHTML={{ __html: txt }} />
    </>
    );
  }
}
