import React from 'react';
import fetch from 'isomorphic-fetch';
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import $ from 'jquery';

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  OPTIONS: '',
};

export function post(url, data) {
  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  }).then(response => response);
}

export function get(url) {
  return fetch(url, {
    method: 'GET',
    headers,
  }).then(response => response.json());
}
export function showLoading() {
   $('#loading').css('display', 'block');
}
export function hideLoading() {
   $('#loading').css('display', 'none');
}