import React from 'react';
import { ReactFormBuilder, ReactFormGenerator } from 'react-form-builder2';
import Popup from 'react-popup';
import $ from 'jquery';

import { get, post, withRouter, showLoading, hideLoading } from './funcs';
import SSFormsHeader from './header';
import SSFormsCustomCSS from './customcss';

const getUrl = (action, cid, fid, key) => `${process.env.REACT_APP_API_URL}/v1/webhook/form/${action}?company=${cid}&id=${fid}&key=${key}`;
const getTemplateUrl = (fid) => `${process.env.REACT_APP_API_URL}/v1/webhook/form/template/get?id=${fid}`;
const getPreviewUrl = (cid, fid, key) => `/forms/preview/${cid}/${fid}/${key}`;

const items = [{  
  key: 'Header',
}, {
  key: 'Label',
}, {
  key: 'Paragraph',
}, {
  key: 'LineBreak',
}, {
  key: 'Dropdown',
}, {
  key: 'Tags',
}, {
  key: 'Checkboxes',
}, {
  key: 'RadioButtons',
}, {
  key: 'TextInput',
}, {
  key: 'NumberInput',
}, {
  key: 'TextArea',
}, {
  key: 'TwoColumnRow',
}, {
  key: 'ThreeColumnRow',
}, {
  key: 'MultiColumnRow',
  col_count: 4
}, {
  key: 'Image',
}, {
  key: 'Rating',
}, {
  key: 'DatePicker',
  dateFormat: 'dd/MM/yyyy'
}, {
  key: 'Signature',
}, {
  key: 'HyperLink',
}, {
  key: 'Range',
}, {
  key: 'Camera',
}];

class SSForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { companyID: this.props.company, formID: this.props.id, key: this.props.fKey, data: [], settings: [], unsaved:false };
  }

  onLoad = (params) => {
    const url = getUrl('get', this.state.companyID, this.state.formID, this.state.key);
    return get(url).then(data => {hideLoading(); this.setState(prevState => ({...prevState, data: {task_data: JSON.parse(data.form_data)}, settings: data.settings})); return JSON.parse(data.form_data);});
  };

  onPost = (data) => {
     //this runs on every change made to the form - we only want to save changes to the DB when the Save button is pressed
    this.setState(prevState => ({...prevState, data: data, unsaved: true}));
  };
  
  previewForm() {
     //Preview button was pressed - check if we need to prompt for Save first
     const previewUrl = getPreviewUrl(this.state.companyID, this.state.formID, this.state.key);
     if (this.state.unsaved) {
        Popup.create({
            title: 'Save Form?',
            content: 'You must save the form to be able to preview it',
            buttons: {
              left: [{
                  text: 'Yes',
                  className: 'success',
                  action: function () {
                     console.log(this);
                      Popup.close();
                      window.navTo = previewUrl;
                      $('#btnSaveForm').click();
                  }
              },
               {
                  text: 'Cancel',
                  action: function () {
                      Popup.close();
                  }
               }]
            }
           });
     } else {
      window.open(previewUrl);
     }
  }
  
  saveForm() {
     //Save button was pressed - save results to DB now
     const saveUrl = getUrl('update', this.state.companyID, this.state.formID, this.state.key);
     showLoading();
     post(saveUrl, this.state.data.task_data).then(data => {
        hideLoading();
        if (data.status === 200) {
           this.setState(prevState => ({...prevState, unsaved: false}));
           if (window.navTo) {
              window.open(window.navTo);
            window.navTo = null;
           } else 
           Popup.create({
            title: 'Success!',
            content: 'Your form has been saved.',
            buttons: {
              left: [{
                  text: 'OK',
                  className: 'success',
                  action: function () {
                      /** Close this popup. Close will always close the current visible one, if one is visible */
                      Popup.close();
                  }
              }]
            }
           });
        } else
           Popup.create({
            title: 'Error',
            content: 'There was an unexpected error saving your form.',
            buttons: {
              left: [{
                  text: 'Close',
                  action: function () {
                      /** Close this popup. Close will always close the current visible one, if one is visible */
                      Popup.close();
                  }
              }]
            }
           });
     });
  }

  render() {
    return (
    <>
      <div className="App">
        <ReactFormBuilder
          toolbarItems={items}
          onLoad={this.onLoad}
          onPost={this.onPost}
        />
      </div>
      <SSFormsCustomCSS settings={this.state && this.state.settings ? this.state.settings : null} />
      </>
    );
  }
}

class SSFormCombined_withoutRouter extends React.Component {
  constructor(props) {
    super(props);
    this.childForm = null;
  }

  render() {
    return (
    <>
      <SSFormsHeader variables={""} onSaveForm={ () => this.childForm.saveForm() } onPreviewForm={ () => this.childForm.previewForm() } />
      <SSForm ref={instance => { this.childForm = instance; }} company={this.props.router.params.company} id={this.props.router.params.id} fKey={this.props.router.params.fKey} />
      </>
    );
  }
}
export default withRouter(SSFormCombined_withoutRouter);
export const SSFormCombined = withRouter(SSFormCombined_withoutRouter);

class SSFormPreview_withoutRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { companyID: this.props.router.params.company, formID: this.props.router.params.id, key: this.props.router.params.fKey, data: null };
  }

  componentDidMount() {
     const url = getUrl('get', this.state.companyID, this.state.formID, this.state.key);
     return get(url).then(data => {hideLoading(); this.setState(prevState => ({...prevState, data: JSON.parse(data.form_data), settings: data.settings})); return JSON.parse(data.form_data);});
  };
  

  onSubmit = (data) => {
    //do nothing on submit - this is a preview
  };

  render() {
    return (
    <>
       {this.state.data ? 
       <>
         <ReactFormGenerator
          onSubmit={this.onSubmit}
          answer_data={[]}
          data={this.state.data ? this.state.data : []} // Question data
         />
         <SSFormsCustomCSS settings={this.state.settings ? this.state.settings : null} />
       </>
         : null }
    </>
    );
  }
}

export const SSFormPreview = withRouter(SSFormPreview_withoutRouter);

class SSFormTemplatePreview_withoutRouter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { formID: this.props.router.params.id, data: null };
  }

  componentDidMount() {
     const url = getTemplateUrl(this.state.formID);
     return get(url).then(data => {hideLoading(); this.setState(prevState => ({...prevState, data: data, settings: data.settings})); return data;});
  };
  

  onSubmit = (data) => {
    //do nothing on submit - this is a preview
  };

  render() {
    return (
    <>
       {this.state.data ? 
       <>
         <ReactFormGenerator
          onSubmit={this.onSubmit}
          answer_data={[]}
          data={this.state.data ? this.state.data : []} // Question data
         />
         <SSFormsCustomCSS settings={this.state.settings ? this.state.settings : null} />
       </>
         : null }
    </>
    );
  }
}

export const SSFormTemplatePreview = withRouter(SSFormTemplatePreview_withoutRouter);